import { renderPrint } from "./render-styles"
import { downloadUri } from "./utils";

const INCH_IN_MM = 25.4;
const mmToPixels = (mm: number, dpi: number) => Math.round((dpi * mm) / INCH_IN_MM)

const getMetadataLink = (warriorId: number) => 
  `https://bafkreihrfk7vrefkktjpug4desyfemprpbwl6ujywjker5luswgajqbtem.ipfs.avaxwarriors.io/metadata/${warriorId}.json`

const renderWarrior = async (warriorId: number, style: 'metal' | 'print', size: PrintSizes, dpi: number, align: string): Promise<string> => {

  const metadataResponse = await fetch(getMetadataLink(Number(warriorId)))
  const data = await metadataResponse.json()

  const canvasSizeMM = printSizeTomm(size)
  const canvasSize = { width: mmToPixels(canvasSizeMM.width, dpi), height: mmToPixels(canvasSizeMM.height, dpi) }

  return await renderPrint(Number(warriorId), data['name'], style as 'metal' | 'paper', canvasSize, align as 'center' | 'top')
}

const createSelect = (options: string[] | number[], label: string): [HTMLSelectElement, HTMLLabelElement] => {
  const optionElements = options.map(option => {
    const optionElement = document.createElement('option')
    optionElement.innerHTML = String(option)
    optionElement.value = String(option)
    return optionElement
  })

  const selectElement = document.createElement('select')
  selectElement.id = label
  optionElements.map(option => {
    selectElement.appendChild(option)
  })

  const labelElement = document.createElement('label')
  labelElement.htmlFor = label
  labelElement.innerHTML = label

  return [selectElement, labelElement]
}

const addOptionsToSelect = (options: string[] | number[], select: HTMLSelectElement): HTMLSelectElement => {
  const optionElements = options.map(option => {
    const optionElement = document.createElement('option')
    optionElement.innerHTML = String(option)
    optionElement.value = String(option)
    return optionElement
  })

  optionElements.map(option => {
    select.appendChild(option)
  })

  return select
}

export const printSizeTomm = (printSize: PrintSizes): {width: number, height: number} => {
  switch(printSize)
  {
    case PrintSizes.A4: return { width: 210, height: 297 }
    case PrintSizes.A3: return { width: 297, height: 420 }
    case PrintSizes.x30x40: return { width: 300, height: 400 }
    default: throw new Error(`Unsupported printSize: ${printSize}`)
  }
}

export enum PrintSizes {
  A4 = 'A4',
  A3 = 'A3',
  x30x40 = '30cm X 40cm'
}

const setupUI = () => {
  const orderIdInput = document.querySelector('#orderId') as HTMLInputElement
  const warriorIdInput = document.querySelector('#warriorId') as HTMLInputElement
  
  const styleOptions = ['metal', 'paper']
  const styleSelect  = addOptionsToSelect(styleOptions, document.querySelector('#style') as HTMLSelectElement)

  const sizeOptions: PrintSizes[] = [
    PrintSizes.x30x40,
    PrintSizes.A3, 
    PrintSizes.A4,
  ]
  const sizeSelect  = addOptionsToSelect(sizeOptions, document.querySelector('#size') as HTMLSelectElement)
  
  const dpiOptions = [300, 400, 500, 600];
  const dpiSelect  = addOptionsToSelect(dpiOptions, document.querySelector('#dpi') as HTMLSelectElement)
  
  const alignOptions = ['top', 'center'];
  const alignSelect  = addOptionsToSelect(alignOptions, document.querySelector('#align') as HTMLSelectElement)

  const button = document.querySelector('#generate') as HTMLButtonElement
  button.innerHTML = "Go"
  button.addEventListener('click', async () => {

    const spinner = document.querySelector("div#spinner") as HTMLDivElement
    spinner.setAttribute('style', 'display: block;')

    const orderId   = orderIdInput.value
    const warriorId = Number(warriorIdInput.value)
    const style     = styleSelect.value as 'metal' | 'print'
    const size      = sizeSelect.value
    const dpi       = Number(dpiSelect.value)
    const align     = alignSelect.value

    const dataURL = await renderWarrior(warriorId, style, size as PrintSizes, dpi, align)
    const img = document.querySelector('#result') as HTMLImageElement
    img.src = dataURL
    spinner.setAttribute('style', 'display: none;')

    downloadUri(`${orderId}.png`, 'data:image/png;base64', dataURL)
  })
}

setupUI()