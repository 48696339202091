import { fabric } from "fabric"
import FontFaceObserver from "fontfaceobserver"
import { loadImage, loadSvg, setBackgroundColor } from "./fabric-promises"
import { genQrCode, roundedCorners, scaleFuncs } from "./utils"

const getWarriorLink = (warriorId: number) =>
  `https://warriorsclub.xyz/collections/avax-warriors/${warriorId}`

const getWarriorImage = (warriorId: number) =>
  `https://bafkreihrfk7vrefkktjpug4desyfemprpbwl6ujywjker5luswgajqbtem.ipfs.avaxwarriors.io/images/${warriorId}.png`


export const renderPrint = async (
  warriorId: number, 
  warriorName: string, 
  style: 'metal' | 'paper', 
  canvasSize: { width: number, height: number }, 
  align: 'center' | 'top' = 'center'
): Promise<string> => {

  // metal
  var qrBgColor = '#000000'
  var qrDotColor = '#FFFFFF'
  var canvasBgColor = '#000000'
  var textColor = '#FFFFFF'

  if (style === 'paper') {
    qrBgColor = '#ffffff'
    qrDotColor = '#000000'
    canvasBgColor = '#FFFFFF'
    textColor = '#000000'
  }
  const canvas = new fabric.Canvas("canvas")
  await setBackgroundColor(canvasBgColor)(canvas)

  const {
    scalePoint,
    scaleMag
  } = scaleFuncs(1)

  const fontBold = new FontFaceObserver('Montserrat', { style: 'normal', weight: 'bold' });
  const fontNormal = new FontFaceObserver('Montserrat', { style: 'normal', weight: 'normal' });
  const fontItalic = new FontFaceObserver('Montserrat', { style: 'italic', weight: 'bold' });
  await fontBold.load("hello")
  await fontNormal.load("hello")
  await fontItalic.load("hello")

  const image = await loadImage(getWarriorImage(warriorId))
  image.scaleToHeight(scaleMag(100))
  image.setPositionByOrigin(scalePoint(new fabric.Point(0, 0)), 'left', 'top')
  if (style === 'paper')
    image.set("clipPath", roundedCorners(image, 5))

  var avaxwarriors = new fabric.Text("AVAXWARRIORS", {
    fontFamily: "Montserrat",
    left: scaleMag(0),
    top: scaleMag(104),
    fontSize: scaleMag(4),
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: "left",
    fill: textColor,
    originX: 'left',
    originY: 'top'
  });

  var warriorNameObj = new fabric.Text(warriorName, {
    fontFamily: "Montserrat",
    left: scaleMag(0),
    top: scaleMag(111),
    fontSize: scaleMag(7),
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: 'left',
    fill: '#FE110E'
  })

  var description = new fabric.Textbox("AVAXWARRIORS 8,888 Warriors. They were created by Alfa, the worlds most advanced Artificial Intelligence.", {
    width: scaleMag(60),
    left: scaleMag(0),
    top: scaleMag(123),
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: scaleMag(3),
    textAlign: 'left',
    fill: textColor
  })

  const qr = await loadSvg(genQrCode(getWarriorLink(warriorId), qrBgColor, qrDotColor))
  qr.scaleToWidth(scaleMag(15))
  qr.setPositionByOrigin(scalePoint(new fabric.Point(85, 115)), 'left', 'top')

  const presentedBy = new fabric.Textbox('PRESENTED BY', {
    width: scaleMag(14),
    left: scaleMag(85),
    top: scaleMag(129.6),
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: 1,
    fill: textColor,
  })

  const warriorsLogo = await loadImage('https://warriorsclub.xyz/assets/logo-metal.png')
  warriorsLogo.scaleToWidth(scaleMag(15))
  warriorsLogo.setPositionByOrigin(scalePoint(new fabric.Point(85, 132)), 'left', 'top')

  const container = new fabric.Group([image, avaxwarriors, warriorNameObj, description, qr, presentedBy, warriorsLogo], {
    padding: 5
  })

  const originalWidth = container.getScaledWidth()

  canvas.setWidth(canvasSize.width)
  canvas.setHeight(canvasSize.height)
  container.scaleToWidth(canvasSize.width)
  const paddingTop = container.getScaledWidth() / originalWidth * 5

  if (align === 'top') container.setPositionByOrigin(new fabric.Point(canvasSize.width / 2, paddingTop), 'center', 'top')
  else container.setPositionByOrigin(new fabric.Point(canvasSize.width / 2, canvasSize.height / 2), 'center', 'center')

  canvas.add(container)
  canvas.renderAll()

  return canvas.toDataURL({ quality: 1, format: 'png' })
}