import { fabric } from 'fabric'
import { Object } from 'fabric/fabric-impl'

import QRCode from 'qrcode-svg'

export const genQrCode = (url: string, bgColor: string, dotColor: string) => {
  return new QRCode({
    content: url,
    background: bgColor,
    color: dotColor,
    width: 200,
    height: 200,
    container: 'svg-viewbox'
  }).svg()
}

export const scaleFuncs = (scalar: number) => {
  return ({
    scalePoint: (point: fabric.Point) => new fabric.Point(point.x * scalar, point.y * scalar),
    scaleMag: (mag: number) => mag * scalar
  })
}

export const roundedCorners = (fabricObject: Object, cornerRadius: number) => {
  return new fabric.Rect({
    width: fabricObject.width!-2,
    height: fabricObject.height!-2,
    rx: cornerRadius / fabricObject.scaleX!,
    ry: cornerRadius / fabricObject.scaleY!,
    left: (-fabricObject.width! + 1) / 2,
    top: (-fabricObject.height! + 1) / 2
  })
}

export const downloadUri = (filename: string, type: string, data: string) => {
  if (isMsie()) {
    var blob = new Blob([data], {type: type})
    navigator.msSaveBlob(blob, filename)
  } else {
    var link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function isMsie() {
  return uaContains('MSIE ') || uaContains('Trident/') || uaContains('Edge/')
}

function uaContains(str: string) {
  return window.navigator.userAgent.indexOf(str) >= 0
}