import { fabric } from 'fabric'
import { Image, Group, Canvas } from 'fabric/fabric-impl'

export const loadImage = async (url: string): Promise<Image> => {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(url, (image) => {
      resolve(image)
    }, {crossOrigin: 'anonymous'})
  })
}

export const loadSvg = async (svg: string): Promise<Group> => {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromString(svg, (objects) => {
      const svg = new fabric.Group(objects)
      resolve(svg)
    })
  })
}

export const setBackgroundColor = (color: string) => async (canvas: Canvas): Promise<void> => {
  return new Promise((resolve, reject) => {
    canvas.setBackgroundColor(color, () => {
      resolve()
    })
  })
}